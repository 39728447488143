@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000; /* 设置背景颜色以匹配您的风格 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.g2-tooltip {
  background-color: #1f1f1f !important; /* 设置为透明或任何所需的颜色 */
}

@layer components {
  .nCenter {
    @apply flex items-center justify-center;
  }
}
